<template>
    <!-- 渠道管理 - 学员管理 -->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">招生管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员管理</a>
                </span>
            </div>
            <!-- 主体区域 -->
            <div class="framePage-body">
                <!-- 搜索区域 -->
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <div class="searchbox">
                        <div title="姓名" class="searchboxItem ">
                            <span class="itemLabel">姓&emsp;&emsp;名:</span>
                            <el-input v-model="searchForm.userName" placeholder="请输入姓名" type="text" clearable size="small"/>
                        </div>
                        <div title="手机号" class="searchboxItem ">
                            <span class="itemLabel">手机号:</span>
                            <el-input v-model="searchForm.mobile" placeholder="请输入联系方式" type="text" clearable size="small"/>
                        </div>
                        <div title="培训名称" class="searchboxItem ">
                            <span class="itemLabel">培训名称:</span>
                            <el-input v-model="searchForm.projectName" placeholder="请输入培训名称" type="text" clearable size="small"/>
                        </div>
                        <div title="审核状态" class="searchboxItem ">
                            <span class="itemLabel">审核状态:</span>
                            <el-select v-model="searchForm.auditState" placeholder="请选择审核状态" clearable size="small" >
                              <el-option
                                  v-for="item in auditStateList"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                              ></el-option>
                            </el-select>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                        </div>
                    </div>
                </div>
                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                width="50"
                            />
                            <el-table-column
                                label="姓名"
                                prop="userName"
                            />
                            <el-table-column
                                label="联系方式"
                                prop="mobile"
                            />
                            <el-table-column
                                label="报名方式"
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary("CT_JOIN_TYPE", row.joinType)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="培训名称"
                                prop="projectName"
                            />
                            <el-table-column
                                label="招生渠道"
                                prop="channelName"
                            />
                            <el-table-column
                                label="审核结果"
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary("CT_USER_AUDIT_STATE", row.auditState)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="备注"
                                prop="auditReason"
                            />
                            <el-table-column
                                label="操作"
                                min-width="110px"
                            >
                                <template v-slot="{row, $index}">
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="editStudent(row, $index)">编辑</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="deleteStudent(row)">删除</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="toDetail(row, $index)">详情</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    import { resetKeepAlive } from "@/utils/common";
    export default {
        name:"studentManagement",
        components:{
            Empty,
            PageNum
        },
        mixins:[List],
        data(){
            return{
                // 搜索表单
                searchForm:{
                    userName:"",//姓名
                    mobile:"",//联系方式
                    projectName:"",//培训名称
                    auditState:"",//审核状态
                },
                // 分页器数据
                pagination:{
                    total: 0,//总条数
                    size: 10,//每页几条数据
                    current: 1//当前页
                },
                //表格数据
                listData:[],
                auditStateList: [],
            }
        },
        methods:{
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                // 处理上一页面路由传参（必须先执行这行代码）
                this.projectId=this.$route.query.projectId;
                // 获取列表数据
                this.getListData();
            },
          //审核状态
          getAuditList() {
            const auditStateList = this.$setDictionary("CT_USER_AUDIT_STATE", "list");
            const list = [];
            for (const key in auditStateList) {
              list.push({
                value: key,
                label: auditStateList[key]
              });
            }
            this.auditStateList = list;
          },
            // 获取列表数据
            getListData(current){
                const {searchForm,pagination}=this;
                if(current) this.pagination.current=current;
                this.$post("/biz/ct/user/ctProjectChannelUserList",{
                    pageNum:pagination.current,
                    pageSize:pagination.size,
                    ...searchForm
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 编辑学员
            editStudent(row, $index){
                const {userId,projectId, projectName}=row;
                this.$router.push({
                    path:"/web/studentUpdate",
                    query:{
                        projectId,
                        projectName,
                        userId,
                        // 当前数据下标，从1开始
                        // index: (this.pagination.current - 1) * this.pagination.size + $index + 1,
                        // total: this.pagination.total,
                        searchForm: JSON.stringify(this.searchForm),
                        // origin:this.isStatistics?"鉴定报名统计":'报名',
                        // type: "depart",
                        // isStatistics: this.isStatistics
                    }
                });
            },
            // 删除学员
            deleteStudent(row){
                const {userId,projectId}=row;
                this.$confirm('是否确认要删除此学员的报名信息，删除后将不能恢复？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    confirmButtonClass:"bgc-bv",
                }).then(() => {
                    this.$post("/biz/ct/user/deleteCtProjectUser",{
                        projectId,
                        userId
                    }).then(()=>{
                        this.getListData();
                        this.$message.success("删除成功");
                    });
                });
            },
            // 查看详情
            toDetail(row, $index){
                const {userId,projectId}=row;
                this.$router.push({
                    path:"/web/studentDetails",
                    query:{
                        projectId,
                        userId,
                        // 当前数据下标，从1开始
                        index: (this.pagination.current - 1) * this.pagination.size + $index + 1,
                        total: this.pagination.total,
                        searchForm: JSON.stringify(this.searchForm),
                        origin:"渠道学员管理",
                        type: "channel",
                    }
                });
            },
            // 每页条数改变
            sizeChange(size){
                this.pagination.size=size;
                this.getListData();
            },
            // 当前页改变
            currentChange(current){
                this.pagination.current=current;
                this.getListData();
            },
            // 设置表格高度
            getTableHeight(opDom = false, page = true) {
                let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom)  tHeight -= 40 + 0.675 * 16 + 1;
                if (page)  tHeight -= 50;
                this.tableHeight = tHeight;
            },
        },
        watch:{
            $route(route,oldRoute){
                if(oldRoute.path==="/web/studentDetails") {
                    this.getListData();
                }
                if(from.path==="/web/studentUpdate") {
                    this.getListData();
                }
            }
        },
        created(){
            this.initData();
            this.getAuditList();
        },
        beforeRouteLeave: resetKeepAlive
    }
</script>
